import { Button } from '@goatlab/react-ui'
import {
  CalendarDays,
  LayoutDashboard,
  LogOut,
  Settings,
  SquareUser,
} from 'lucide-react'
import { useRouter } from 'next/router'
import { FC } from 'react'
import { useAuth } from '@components/auth/store/auth.store'
import Link from '@components/ui/Link'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@components/ui/dropdown-menu'
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@components/ui/images/avatar'
import { useStateAwareRoutes } from '@src/hooks/useStateAwareRoutes'
import { useTypedTranslation } from '@src/utils/useTypedTranslation'

export const UserNav: FC = () => {
  const { user, logOut } = useAuth()
  const router = useRouter()
  const stateRouter = useStateAwareRoutes()
  const { t } = useTypedTranslation()

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="relative h-6 w-6 md:h-8 md:w-8 rounded-full">
          <div className="flex-col align-center">
            <div className="flex items-center flex-col mt-2">
              <Avatar className="h-5 w-5 md:h-7 md:w-7" data-cy="user-avatar">
                <AvatarImage
                  src={user?.account?.profilePicture || user?.profilePicture}
                  alt={user?.account?.firstName || undefined}
                />
                <AvatarFallback>{user?.account?.firstName}</AvatarFallback>
              </Avatar>
              <span className="text-xs">You</span>
            </div>
          </div>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end" forceMount>
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">
              {user?.account?.displayName || undefined}
            </p>
            <p className="text-xs leading-none text-muted-foreground">
              {user?.email}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <Link
            href={
              user?.account?.slug
                ? stateRouter.dynamic.SPACE_HOME({
                    space: user?.account?.slug,
                  })
                : '#'
            }
            className={'text-sm  xl:text-base text-heading'}>
            <DropdownMenuItem>
              <SquareUser className="mr-2 h-4 w-4" />
              <span data-cy="dropdown-user-profile">{t('my-profile')}</span>
            </DropdownMenuItem>
          </Link>
          <Link
            href={stateRouter.dynamic.ACCOUNT_DASHBOARD()}
            className={'text-sm  xl:text-base text-heading'}>
            <DropdownMenuItem>
              <LayoutDashboard className="mr-2 h-4 w-4" />
              <span data-cy="dropdown-user-dashboard">
                {t('text-dashboard')}
              </span>
            </DropdownMenuItem>
          </Link>
          <Link
            href={stateRouter.dynamic.ACCOUNT_CALENDAR()}
            className={'text-sm xl:text-base text-heading'}>
            <DropdownMenuItem>
              <CalendarDays className="mr-2 h-4 w-4" />
              <span>{t('text-calendar')}</span>
            </DropdownMenuItem>
          </Link>

          <Link
            href={stateRouter.dynamic.ACCOUNT_SETTINGS()}
            className={'text-sm xl:text-base text-heading'}>
            <DropdownMenuItem>
              <Settings className="mr-2 h-4 w-4" />
              <span>{t('text-settings')}</span>
            </DropdownMenuItem>
          </Link>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onClick={async () => {
            await logOut()
            await router.push('/')
          }}>
          <LogOut className="mr-2 h-4 w-4" />
          <span>{t('text-logout')}</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
