import { TypesenseMultiSearchQuery } from '@goatlab/trpc'
import { useSearch } from '@contexts/search/search.context'
import { useSite } from '@contexts/site/site.context'

export const useGlobalSearchFilter = () => {
  const { isSpace, accountId, isExternalDomain } = useSite()
  const perPage = 10
  const { q, categories } = useSearch()
  const productSearchArray: string[] = []
  const organizationSearchArray: string[] = []
  const staffSearchArray: string[] = []

  if (categories?.length) {
    productSearchArray.push(`categories:${categories.join(',')}`)
  }

  if (isSpace || isExternalDomain) {
    productSearchArray.push(`accountId:${accountId}`)
    // Exact search
    staffSearchArray.push(`organizations:=${accountId}`)
  }

  const arrayFilter: TypesenseMultiSearchQuery = [
    {
      q: q || '',
      collection: 'products',
      filter_by: productSearchArray.join('&'),
      query_by: '*',
      per_page: perPage,
    },
    {
      q: q || '',
      collection: 'staff',
      filter_by: staffSearchArray.join('&'),
      query_by: '*',
      per_page: perPage,
    },
  ]

  // Search for organizations only if this
  // is not a space related search
  if (!isSpace) {
    arrayFilter.push({
      q: q || '',
      collection: 'organizations',
      filter_by: organizationSearchArray.join('&'),
      query_by: '*',
      per_page: perPage,
    })
  }

  return arrayFilter
}
