import cn from 'clsx'
import { FC, ReactNode } from 'react'

interface CookieBarProps {
  className?: string
  title: string
  description?: string
  hide?: boolean
  action?: ReactNode
}

export const CookieBar: FC<CookieBarProps> = ({
  title,
  description,
  className,
  action,
  hide,
}) => {
  return (
    <div
      className={cn(
        'text-center p-5 bg-white text-sm flex-row justify-center items-center font-medium fixed bottom-0 w-full sm:z-50 transition-all duration-300 ease-out shadow-cookies ',
        {
          transform: true,
          'translate-y-0 opacity-100': !hide,
          'translate-y-full opacity-0': hide,
        },
        className,
      )}>
      <span className="inline-block mb:block mb-3.5 leading-6">{title}</span>
      <span className="inline-block md:ms-3">{description}</span>
      {action && action}
    </div>
  )
}
