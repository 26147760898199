import { useAccountStore } from '@goatlab/marketplace-states'
import { Button } from '@goatlab/react-ui'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@goatlab/react-ui'
import { Dialog, DialogTrigger } from '@goatlab/react-ui'
import { Popover, PopoverContent, PopoverTrigger } from '@goatlab/react-ui'
import { Account } from '@prisma/generated/zod'
import { CheckIcon, ChevronsUpDown, PlusCircleIcon } from 'lucide-react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'
import * as React from 'react'
import { useAuth } from '@components/auth/store/auth.store'
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@components/ui/images/avatar'
import { useStateAwareRoutes } from '@src/hooks/useStateAwareRoutes'
import { cn } from '@utils/cn'

const CreateSubAccountWizard = dynamic(() =>
  import('@components/account/create/CreateAccountWizard').then(
    mod => mod.CreateAccountWizard,
  ),
)

interface Group {
  label: string
  accounts: Account[]
}

type PopoverTriggerProps = React.ComponentPropsWithoutRef<typeof PopoverTrigger>

interface AccountSwitcherProps extends PopoverTriggerProps {}

export const AccountSwitcher: FC<AccountSwitcherProps> = ({ className }) => {
  const [open, setOpen] = React.useState(false)
  const [showNewAccountDialog, setShowNewAccountDialog] = React.useState(false)
  const { selectedAccount } = useAccountStore()
  const { user } = useAuth()
  const router = useRouter()
  const routes = useStateAwareRoutes()

  const accountId: string | undefined = selectedAccount?.id

  const accounts: Account[] = []

  const collaborations = user?.account?.collaborations || []

  for (const collaboration of collaborations) {
    accounts.push({
      ...collaboration.account,
      created: new Date(collaboration.account.created),
      deleted: new Date(collaboration.account.created),
      updated: new Date(collaboration.account.created),
    })
  }

  const displayName =
    user?.account?.displayName ||
    `${user?.account?.firstName} ${user?.account?.lastName}`

  const personalAccounts: Account[] =
    user && user.account ? [user?.account] : []

  const groups: Group[] = [
    {
      label: 'Personal',
      accounts: personalAccounts,
    },
    {
      label: 'Accounts',
      accounts,
    },
  ]

  const [selectedTeam, setSelectedTeam] = useState<Account>()

  useEffect(() => {
    const foundAccount = accounts.find(o => o.id === accountId)
    const foundPersonalAccount = personalAccounts.find(a => a.id === accountId)

    if (foundPersonalAccount) {
      setSelectedTeam(foundPersonalAccount)
    } else if (foundAccount) {
      setSelectedTeam(foundAccount)
    } else if (!accountId) {
      setSelectedTeam(personalAccounts[0])
    }
  }, [accountId, user, displayName])

  return (
    <Dialog open={showNewAccountDialog} onOpenChange={setShowNewAccountDialog}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            id="accountSwitcher"
            variant="outline"
            role="combobox"
            aria-expanded={open}
            aria-label="Select a team"
            className={cn('w-48 justify-between', className)}>
            <Avatar className="mr-2 h-5 w-5">
              <AvatarImage
                src={
                  selectedAccount?.imageUrl ||
                  `https://avatar.vercel.sh/${selectedTeam?.displayName}.png`
                }
                alt={selectedTeam?.displayName}
              />
              <AvatarFallback>SC</AvatarFallback>
            </Avatar>
            <div className="text-xs whitespace-nowrap text-ellipsis overflow-hidden ...">
              {selectedTeam?.displayName}
            </div>
            <ChevronsUpDown className="ml-auto h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[200px] p-0">
          <Command className="z-600">
            <CommandInput placeholder="Search account..." />
            <CommandList>
              <CommandEmpty>No organization found.</CommandEmpty>
              {groups.map(group => (
                <CommandGroup key={group.label} heading={group.label}>
                  {group.accounts.map(acc => (
                    <CommandItem
                      key={acc.id || '*'}
                      onSelect={async () => {
                        setSelectedTeam(acc)
                        setOpen(false)
                        await router.push(
                          routes.dynamic.ACCOUNT_DASHBOARD({
                            accountId: acc.id,
                          }),
                        )

                        // await router.push(
                        //   router.asPath.replace(
                        //     accountId || '',
                        //     team.value || '',
                        //   ),
                        // )
                      }}
                      className="text-sm">
                      <Avatar className="mr-2 h-5 w-5">
                        <AvatarImage
                          src={
                            acc.profilePicture ??
                            `https://avatar.vercel.sh/${acc.id}.png`
                          }
                          alt={acc.displayName}
                        />
                        <AvatarFallback>ORG</AvatarFallback>
                      </Avatar>
                      {acc.displayName}
                      <CheckIcon
                        className={cn(
                          'ml-auto h-4 w-4',
                          selectedTeam?.id === acc.id
                            ? 'opacity-100'
                            : 'opacity-0',
                        )}
                      />
                    </CommandItem>
                  ))}
                </CommandGroup>
              ))}
            </CommandList>
            <CommandSeparator />
            <CommandList>
              <CommandGroup>
                <DialogTrigger asChild>
                  <CommandItem
                    onSelect={() => {
                      setOpen(false)
                      setShowNewAccountDialog(true)
                    }}
                    data-cy="create-organization">
                    <PlusCircleIcon className="mr-2 h-5 w-5" />
                    Create Organization
                  </CommandItem>
                </DialogTrigger>
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      <CreateSubAccountWizard setDialogOpen={setShowNewAccountDialog} />
    </Dialog>
  )
}
