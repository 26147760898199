import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
} from '@goatlab/react-ui'
import { FC } from 'react'
import { ListItem } from './GealiumCategoryMenu'
import { MenuItem } from '@src/utils/buildHeaders'

interface SpaceMenuProps {
  menu: MenuItem[]
}

export const SpaceMenu: FC<SpaceMenuProps> = ({ menu }) => {
  return (
    <NavigationMenu>
      <NavigationMenuList>
        {menu.map(menuItem => {
          return (
            <NavigationMenuItem key={menuItem.id}>
              <NavigationMenuTrigger>{menuItem.label}</NavigationMenuTrigger>
              <NavigationMenuContent>
                <ul className="grid gap-3 p-6 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
                  {menuItem?.columns?.map(m => {
                    return m?.columnItems?.flatMap(column => (
                      <ListItem
                        href={column.path}
                        key={column.label}
                        title={column.label}>
                        <span className="overflow-hidden overflow-ellipsis line-clamp-2">
                          {column.description}
                        </span>
                      </ListItem>
                    ))
                  })}
                </ul>
              </NavigationMenuContent>
            </NavigationMenuItem>
          )
        })}
      </NavigationMenuList>
    </NavigationMenu>
  )
}
