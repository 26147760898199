import { Instagram, Twitter, Facebook, Youtube } from 'lucide-react'
import { siteSettings } from '@src/api/settings/site-settings'

export const footerData = {
  widgets: [
    {
      id: 0,
      isCompanyIntroduction: true,
      description: 'The evolution of preventive health',
      lists: [
        {
          id: 1,
          title: 'link-instagram',
          path: 'https://www.instagram.com/gealium_com',
          icon: <Instagram />,
        },
        {
          id: 2,
          title: 'link-twitter',
          path: 'https://twitter.com/gealium',
          icon: <Twitter />,
        },
        {
          id: 3,
          title: 'link-facebook',
          path: 'https://www.facebook.com/gealium',
          icon: <Facebook />,
        },
        {
          id: 4,
          title: 'link-youtube',
          path: 'https://www.youtube.com/@gealium',
          icon: <Youtube />,
        },
      ],
    },
    {
      id: 5,
      widgetTitle: 'widget-title-our-information',
      lists: siteSettings.siteHeader.pagesMenu.map(s => {
        return {
          id: s.id,
          path: s.path,
          title: s.label,
        }
      }),
    },
  ],
  payment: [
    {
      id: 1,
      path: '/',
      image: '/assets/images/payment/mastercard.svg',
      name: 'payment-master-card',
      width: 34,
      height: 20,
    },
    {
      id: 2,
      path: '/',
      image: '/assets/images/payment/visa.svg',
      name: 'payment-visa',
      width: 50,
      height: 20,
    },
  ],
}
