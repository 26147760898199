import { DraggableSyntheticListeners } from '@dnd-kit/core'
import { rectSortingStrategy } from '@dnd-kit/sortable'
import {
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuTrigger,
} from '@goatlab/react-ui'
import clsx from 'clsx'
import { Edit, GripVertical, Loader, PlusCircle } from 'lucide-react'
import { FC } from 'react'
import { EditText } from 'react-edit-text'
import { GridContainer } from '../dragAndDrop/components/GridContainer'
import { Sortable } from '../dragAndDrop/sortable/Sortable'
import { GealiumMenuSubItem } from './GealiumMenuSubItem'
import { MenuItem } from '@src/utils/buildHeaders'

interface GealiumMenuItemProps {
  menu: MenuItem
  editingItem: number | null
  addingChild: boolean
  setAddingChild: (val: boolean) => void
  setEditingItem: (itemId: number | null) => void
  onMenuItemChanged?: (item: MenuItem) => Promise<void>
  onAddChildItemMenuClicked?: (item: MenuItem) => Promise<void>
  onDeleteMenuItem?: (item: MenuItem) => Promise<void>
  onUpdateLinkMenuItemClicked?: (item: MenuItem) => Promise<void>
  dragAndDropListeners?: DraggableSyntheticListeners
  onReOrderItems?: (props: {
    active: MenuItem
    over: MenuItem
    newOrder: MenuItem[]
  }) => Promise<void>
}

export const GealiumMenuItem: FC<GealiumMenuItemProps> = ({
  menu,
  editingItem,
  setEditingItem,
  onMenuItemChanged,
  onUpdateLinkMenuItemClicked,
  onAddChildItemMenuClicked,
  onDeleteMenuItem,
  setAddingChild,
  addingChild,
  dragAndDropListeners,
  onReOrderItems,
}) => {
  const subItems = menu.columns?.flatMap(c => c.columnItems)

  return (
    <NavigationMenuItem>
      <NavigationMenuTrigger>
        <GripVertical
          {...dragAndDropListeners}
          className={clsx('h-4 w-4 text-slate-500', {})}
        />
        <EditText
          defaultValue={menu.label}
          onSave={async e => {
            const newMenu = {
              ...menu,
              label: e.value,
            }
            setEditingItem(menu.id)
            await onMenuItemChanged?.(newMenu)
            setEditingItem(null)
          }}
        />
        {editingItem === menu.id ? (
          <Loader className="animate-spin w-6 h-6 mr-2 text-slate-500" />
        ) : (
          <>
            <div
              onClick={async () => {
                await onUpdateLinkMenuItemClicked?.(menu)
              }}>
              <Edit className="h-4 w-4 ml-4 text-slate-500 cursor-pointer z-20" />
            </div>
          </>
        )}
      </NavigationMenuTrigger>

      <NavigationMenuContent>
        <Sortable
          handle={true}
          items={subItems || []}
          onReOrderItems={onReOrderItems}
          renderItem={({ item, props: { listeners } }) => (
            <GealiumMenuSubItem
              key={item.id}
              menu={item}
              editingItem={editingItem}
              setEditingItem={setEditingItem}
              onMenuItemChanged={onMenuItemChanged}
              onUpdateLinkMenuItemClicked={onUpdateLinkMenuItemClicked}
              onDeleteMenuItem={onDeleteMenuItem}
              dragAndDropListeners={listeners}
            />
          )}
          Container={(props: any) => <GridContainer {...props} columns={2} />}
          strategy={rectSortingStrategy}
        />

        {/* Add more */}
        <div className="w-full min-w-[250px] flex justify-center items-center pb-2">
          {addingChild ? (
            <Loader className="animate-spin w-6 h-6 mr-2 text-slate-500" />
          ) : (
            <PlusCircle
              className="w-6 h-6 m-2 text-slate-500 cursor-pointer"
              onClick={async e => {
                e.preventDefault()
                setAddingChild(true)
                await onAddChildItemMenuClicked?.(menu).finally(() => {
                  setAddingChild(false)
                })
              }}
            />
          )}
        </div>
      </NavigationMenuContent>
    </NavigationMenuItem>
  )
}
