import type { DraggableSyntheticListeners } from '@dnd-kit/core'
import type { Transform } from '@dnd-kit/utilities'
import classNames from 'classnames'
import React, { ForwardedRef, LegacyRef, useEffect } from 'react'
import styles from './item.module.scss'
import { DataWithId } from '@components/ui/tables/VirtualTable'

export interface ItemProps<T extends DataWithId> {
  dragOverlay?: boolean
  color?: string
  disabled?: boolean
  dragging?: boolean
  handle?: boolean
  handled?: any
  height?: number
  index: number
  fadeIn?: boolean
  transform?: Transform | null
  listeners?: DraggableSyntheticListeners
  sorting?: boolean
  style?: React.CSSProperties
  transition?: string | null
  wrapperStyle?: React.CSSProperties
  value: T
  removeItem?(): void
  renderItem: ({
    item,
    props,
  }: {
    item: T
    props: {
      removeItem: () => void
      dragOverlay: boolean
      dragging: boolean
      sorting: boolean
      index: number
      fadeIn: boolean
      listeners: DraggableSyntheticListeners
      ref: LegacyRef<HTMLDivElement> | ForwardedRef<HTMLDivElement>
      style: React.CSSProperties | undefined
      transform: ItemProps<T>['transform']
      transition: ItemProps<T>['transition']
      value: ItemProps<T>['value']
    }
  }) => React.ReactElement
}

export const Item = React.memo(
  React.forwardRef<HTMLDivElement, ItemProps<any>>(
    (
      {
        color,
        dragOverlay,
        dragging,
        disabled,
        fadeIn,
        handle,
        index,
        listeners,
        removeItem,
        renderItem,
        sorting,
        style,
        transition,
        transform,
        value,
        wrapperStyle,
        ...props
      },
      ref,
    ) => {
      useEffect(() => {
        if (!dragOverlay) {
          return
        }

        document.body.style.cursor = 'grabbing'

        return () => {
          document.body.style.cursor = ''
        }
      }, [dragOverlay])

      // @ts-ignore
      if (props && props['role']) {
        // @ts-ignore
        delete props['role']
      }

      return (
        <div
          className={classNames(
            styles.Wrapper,
            fadeIn && styles.fadeIn,
            sorting && styles.sorting,
            dragOverlay && styles.dragOverlay,
          )}
          style={
            {
              transition: [transition, wrapperStyle?.transition]
                .filter(Boolean)
                .join(', '),
              '--translate-x': transform
                ? `${Math.round(transform.x)}px`
                : undefined,
              '--translate-y': transform
                ? `${Math.round(transform.y)}px`
                : undefined,
              '--scale-x': transform?.scaleX
                ? `${transform.scaleX}`
                : undefined,
              '--scale-y': transform?.scaleY
                ? `${transform.scaleY}`
                : undefined,
              '--index': index,
              '--color': color,
            } as React.CSSProperties
          }
          ref={ref as LegacyRef<HTMLDivElement>}>
          <div
            className={classNames(
              styles.Item,
              dragging && styles.dragging,
              handle && styles.withHandle,
              dragOverlay && styles.dragOverlay,
              disabled && styles.disabled,
              color && styles.color,
            )}
            style={style}
            data-cypress="draggable-item"
            {...(handle ? undefined : listeners)}
            {...props}
            tabIndex={handle ? undefined : 0}>
            {renderItem({
              item: value,
              props: {
                removeItem: () => removeItem?.(),
                dragOverlay: Boolean(dragOverlay),
                dragging: Boolean(dragging),
                sorting: Boolean(sorting),
                index,
                fadeIn: Boolean(fadeIn),
                listeners,
                ref,
                style,
                transform,
                transition,
                value,
                ...props,
              },
            })}
          </div>
        </div>
      )
    },
  ),
)
