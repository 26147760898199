import {
  CalendarDays,
  User2,
  Store,
  Newspaper,
  Home,
  GraduationCap,
} from 'lucide-react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { FC, ReactNode } from 'react'
import Scrollbar from '@components/common/scrollbar'
import { defaultProfileImg } from '@components/ui/images/images'
import { SideBarGroupLink } from '@components/ui/sidebar/sidebarLink'
import { useUI } from '@contexts/ui/ui.context'
import { useStateAwareRoutes } from '@src/hooks/useStateAwareRoutes'
import { typesenseService } from '@src/services/typesense/typesense.service'
import {
  TranslationKeys,
  useTypedTranslation,
} from '@src/utils/useTypedTranslation'

export const ProfileNav: FC = () => {
  const {
    query: { space },
  } = useRouter()
  const { Modal } = useUI()
  const { t } = useTypedTranslation('common')
  const { data, isLoading } = typesenseService.useSearchAccount({
    q: String(space),
    query_by: 'slug',
  })
  const stateRouter = useStateAwareRoutes()

  const profileSpaceMenu: {
    slug: string
    name: TranslationKeys['common']
    icon: ReactNode
  }[] = [
    {
      slug: stateRouter.dynamic.SPACE_HOME(),
      name: 'breadcrumb-home',
      icon: <Home className="w-5 h-5" />,
    },
    {
      slug: stateRouter.dynamic.SPACE_BIO(),
      name: 'breadcrumb-bio',
      icon: <User2 className="w-5 h-5" />,
    },
    {
      slug: stateRouter.dynamic.SPACE_POSTS(),
      name: 'breadcrumb-posts',
      icon: <Newspaper className="w-5 h-5" />,
    },
    {
      slug: stateRouter.dynamic.SPACE_COURSES(),
      name: 'breadcrumb-courses',
      icon: <GraduationCap className="w-5 h-5" />,
    },
    {
      slug: stateRouter.dynamic.SPACE_STORE(),
      name: 'breadcrumb-store',
      icon: <Store className="w-5 h-5" />,
    },
  ]

  const profile = data?.hits[0]?.document

  if (!data && !isLoading && !profile) {
    return <>404!</>
  }

  return (
    <>
      <Scrollbar className="menu-scrollbar flex-grow mb-auto">
        <div className="overflow-hidden">
          <div className="flex justify-center mt-10">
            <Image
              alt="profile picture"
              src={profile?.profilePicture || defaultProfileImg}
              className="rounded-full border-solid border-white -mt-3 w-28 h-28"
              width={196}
              height={196}
            />
          </div>
          <div className="text-center px-3 pb-6 pt-2">
            <h3 className=" text-sm bold font-sans">{profile?.displayName}</h3>
          </div>

          <div className="text-center px-3 pb-6 pt-2">
            <button
              className="btn-sm bg-primary-500 hover:bg-primary-800 text-white"
              onClick={() => {
                Modal.openBookAppointment()
              }}>
              <CalendarDays />
              <span className="ml-2">{t('text-book-appointment')}</span>
            </button>
          </div>
        </div>

        <div className="ml-6">
          <span className="font-bold py-2	text-sm">{t('drawer-services')}</span>
        </div>
        <div className="flex flex-col py-7 px-8 lg:px-2 text-heading">
          <ul className="mobileMenu">
            {profileSpaceMenu.map(item => {
              return (
                <SideBarGroupLink
                  href={item.slug}
                  icon={item.icon}
                  name={t(`common.${item.name}`)}
                  key={item.slug}
                />
              )
            })}
          </ul>
        </div>
      </Scrollbar>
    </>
  )
}
