import { useRouter } from 'next/router'
import { FC, ReactNode } from 'react'
import Link from '../Link'
import { useUI } from '@contexts/ui/ui.context'

interface SideBarGroupLinkProps {
  href: string
  icon: ReactNode
  name: string
}
export const SideBarGroupLink: FC<SideBarGroupLinkProps> = ({
  href,
  icon,
  name,
}) => {
  const { pathname } = useRouter()
  const { closeSideBar } = useUI()
  const newPathname = pathname.split('/').slice(2, 3)
  const mainPath = `/${newPathname[0]}`
  const menuPathname = href.split('/').slice(2, 3)
  const menuPath = `/${menuPathname[0]}`

  return (
    <Link key={href} href={href} onClick={closeSideBar}>
      <span
        className={
          mainPath === menuPath
            ? 'group menu-link bg-gray-100 font-semibold flex items-center cursor-pointer text-sm lg:text-base text-heading py-2 px-4 lg:px-5 rounded mb-2'
            : 'group menu-link flex items-center cursor-pointer text-sm lg:text-base text-heading font-normal py-2 px-4 lg:px-5 rounded mb-2 hover:bg-gray-100'
        }>
        {icon}
        <span className="flex-1 ml-2 whitespace-nowrap text-sm">{name}</span>
      </span>
    </Link>
  )
}
