import { X, Facebook, Instagram, Twitter, Youtube } from 'lucide-react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { FC } from 'react'
import { Drawer } from '@components/common/drawer/drawer'
import { MobileFooter } from '@components/layout/footer/mobileFooter'
import { ProfileNav } from '@components/spaces/profileSpaceNav'
import { Logo } from '@components/ui/Logo'
import { useUI } from '@contexts/ui/ui.context'
import { useIs } from '@src/hooks/useIs'
import { getDirection } from '@utils/get-direction'

const HomeDrawerLeft = dynamic(() =>
  import('@components/layout/drawerLeft/HomeDrawerLeft').then(
    i => i.HomeDrawerLeft,
  ),
)

const AccountDrawerLeft = dynamic(() =>
  import('@components/layout/drawerLeft/AccountDrawerLeft').then(
    i => i.AccountDrawerLeft,
  ),
)

const social = [
  {
    id: 0,
    link: 'https://www.facebook.com/gealium',
    icon: <Facebook />,
    className: 'facebook',
    title: 'text-facebook',
  },
  {
    id: 1,
    link: 'https://twitter.com/gealium',
    icon: <Twitter />,
    className: 'twitter',
    title: 'text-twitter',
  },
  {
    id: 2,
    link: 'https://www.youtube.com/@gealium',
    icon: <Youtube />,
    className: 'youtube',
    title: 'text-youtube',
  },
  {
    id: 3,
    link: 'https://www.instagram.com/gealium_com',
    icon: <Instagram />,
    className: 'instagram',
    title: 'text-instagram',
  },
]

const BottomNavigation: FC = () => {
  const { closeSideBar, displaySidebar } = useUI()
  const { locale } = useRouter()
  const dir = getDirection(locale)
  const contentWrapperCSS = dir === 'ltr' ? { left: 0 } : { right: 0 }
  const { isAccountPage, isSpacePage } = useIs()

  return (
    <>
      <MobileFooter />
      <Drawer
        placement={dir === 'rtl' ? 'right' : 'left'}
        open={displaySidebar}
        onClose={closeSideBar}
        contentWrapperStyle={contentWrapperCSS}
        width={dir === 'rtl' ? 'calc(100% - 6rem)' : 'calc(100% - 6rem)'}>
        <div className="w-full border-b border-gray-100 flex justify-between items-center relative ps-5 md:ps-7 flex-shrink-0 py-0.5">
          <Logo keepText={true} />

          <button
            className="flex text-2xl items-center justify-center text-gray-500 px-4 md:px-6 py-6 lg:py-8 focus:outline-none transition-opacity hover:opacity-60"
            onClick={closeSideBar}
            aria-label="close">
            <X className="text-black mt-1 md:mt-0.5" />
          </button>
        </div>
        <div className="flex flex-col justify-between">
          {isAccountPage ? (
            <div className="px-8">
              <AccountDrawerLeft />
            </div>
          ) : isSpacePage ? (
            <ProfileNav />
          ) : (
            <HomeDrawerLeft />
          )}
        </div>

        <div className="flex items-center justify-center bg-white border-t border-gray-100 px-7 flex-shrink-0 space-s-1">
          {social?.map((item, index) => (
            <a
              href={item.link}
              className={`text-heading p-5 opacity-60 first:-ms-4 transition duration-300 ease-in hover:opacity-100 ${item.className}`}
              target="_blank"
              key={index}
              rel="noreferrer">
              <span className="sr-only">{item.title}</span>
              {item.icon}
            </a>
          ))}
        </div>
      </Drawer>
    </>
  )
}

export default BottomNavigation
