import { clsx } from 'clsx'
import React, { ButtonHTMLAttributes, FC } from 'react'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  variant?: 'flat' | 'slim' | 'rounded' | 'outline' | 'ghost'
  active?: boolean
  type?: 'submit' | 'reset' | 'button'
  loading?: boolean
  disabled?: boolean
  color?: 'primary' | 'black' | 'danger' | 'gray' | 'transparent'
}

const baseClasses =
  'py-2 px-4 text-[13px] md:text-xs leading-4 inline-flex items-center cursor-pointer transition ease-in-out duration-300 font-semibold font-body text-center justify-center placeholder-white focus-visible:outline-none focus:outline-none rounded-md mx-1 flex-shrink-0'

const variantClasses: Record<NonNullable<ButtonProps['variant']>, string> = {
  flat: 'h-8',
  slim: 'h-6 md:h-9 px-5',
  rounded: 'h-10 w-10 md:h-12 md:w-12 rounded-full',
  outline:
    'text-current border border-slate-300 hover:bg-accent hover:text-accent-foreground border-slate-300 hover:border-current',
  ghost:
    'text-current bg-transparent hover:bg-accent hover:text-accent-foreground',
}

const stateClasses = {
  loading: 'cursor-not-allowed',
  disabled:
    'cursor-not-allowed hover:cursor-not-allowed bg-gray-300 text-heading',
}

export const Button: FC<ButtonProps> = props => {
  const {
    className,
    variant = 'flat',
    color = 'black',
    type = 'submit',
    children,
    active,
    loading = false,
    disabled = false,
    ...rest
  } = props

  const colorClasses: Record<NonNullable<ButtonProps['color']>, string> = {
    black:
      variant === 'outline'
        ? 'border-gray-800 text-gray-800 hover:border-gray-600 hover:text-gray-600'
        : 'bg-heading text-white hover:bg-gray-600 hover:text-white',
    primary:
      variant === 'outline'
        ? 'border-blue-500 text-blue-500 hover:border-blue-700 hover:text-blue-700'
        : 'bg-primary-500 hover:bg-primary-800 text-white hover:text-white',
    danger:
      variant === 'outline'
        ? 'border-red-500 text-red-500 hover:border-red-700 hover:text-red-700'
        : 'bg-red-200 text-red-700 hover:bg-red-300 hover:text-red-900',
    gray:
      variant === 'outline'
        ? 'border-slate-200 text-gray-500 hover:border-slate-200 hover:text-gray-700 btn-ghost'
        : 'bg-gray-500 hover:bg-slate-300 text-black hover:text-black',
    transparent:
      variant === 'outline'
        ? 'border-transparent text-black hover:border-gray-500 hover:text-gray-500'
        : 'bg-transparent text-black hover:bg-gray-100',
  }

  const rootClassName = clsx(
    baseClasses,
    variantClasses[variant],
    colorClasses[color],
    {
      [stateClasses.loading]: loading,
      [stateClasses.disabled]: disabled,
    },
    className,
  )

  return (
    <button
      aria-pressed={active}
      data-variant={variant}
      className={rootClassName}
      disabled={disabled}
      type={type}
      {...rest}>
      {children}
      {loading && (
        <svg
          className="animate-spin -me-1 ms-3 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24">
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      )}
    </button>
  )
}
