import Image from 'next/image'
import { FC, useState } from 'react'

interface ImageFixedHeightProps {
  src: string
  alt: string
  height: number
  className?: string
}

export const ImageFixedHeight: FC<ImageFixedHeightProps> = ({
  alt,
  height,
  src,
  className,
}) => {
  const [ratio, setRatio] = useState(1)
  const width = height * ratio

  return (
    <div
      className={`object-cover h-auto rounded-md  w-[${width}px] h-[${height}px]`}>
      <Image
        src={src}
        alt={alt}
        width={width}
        height={height}
        className={className || ''}
        onLoad={({ target }) => {
          const t = target as any
          const naturalWidth = t['naturalWidth']
          const naturalHeight = t['naturalHeight']
          if (
            isNaN(naturalHeight) ||
            isNaN(naturalHeight) ||
            naturalHeight === 0
          ) {
            return
          }

          setRatio(naturalWidth / naturalHeight)
        }}
      />
    </div>
  )
}
