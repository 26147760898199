import { HomeIcon, MoreHorizontal, SearchIcon } from 'lucide-react'
import { FC } from 'react'
import AuthMenu from '../header/authMenu'
import { useAuth } from '@components/auth/store/auth.store'
import MenuIcon from '@components/icons/menu-icon'
import { Logo } from '@components/ui/Logo'
import { useSite } from '@contexts/site/site.context'
import { useUI } from '@contexts/ui/ui.context'

export const MobileFooter: FC = () => {
  const { openSideBar, setDrawerView, openSearch } = useUI()
  const { isAuthenticated } = useAuth()
  const { isExternalDomain } = useSite()

  function handleMobileMenu() {
    setDrawerView('MOBILE_MENU')
    openSideBar()
  }

  return (
    <div className="md:hidden fixed bottom-0 left-0 z-50 w-full h-12 bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600">
      <div className="grid h-full max-w-lg grid-cols-5 mx-auto">
        <button
          type="button"
          onClick={handleMobileMenu}
          className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
          <MenuIcon className="text-slate-500 group-hover:text-primary h-4" />
          <span className="text-xs text-slate-500 dark:text-gray-400 group-hover:text-primary dark:group-hover:text-primary">
            Menu
          </span>
        </button>

        <button
          type="button"
          onClick={openSearch}
          className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
          <SearchIcon className="text-slate-500 group-hover:text-primary h-5" />
          <span className="text-xs text-slate-500 dark:text-gray-400 group-hover:text-primary dark:group-hover:text-primary">
            Search
          </span>
        </button>

        <div className="flex items-center justify-center">
          <button
            type="button"
            className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
            {isExternalDomain ? (
              <HomeIcon className="text-slate-500 group-hover:text-primary h-5" />
            ) : (
              <Logo className="h-5 w-5" />
            )}
            <span className="text-xs text-slate-500 dark:text-gray-400 group-hover:text-primary dark:group-hover:text-primary">
              Home
            </span>
          </button>
        </div>

        <button
          type="button"
          onClick={openSearch}
          className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
          <MoreHorizontal className="text-slate-500 group-hover:text-primary h-5" />
          <span className="text-xs text-slate-500 dark:text-gray-400 group-hover:text-primary dark:group-hover:text-primary">
            More
          </span>
        </button>

        <div className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
          <AuthMenu />
          {isAuthenticated() && (
            <span className="text-xs text-slate-500 dark:text-gray-400 group-hover:text-primary dark:group-hover:text-primary">
              Profile
            </span>
          )}
        </div>
      </div>
    </div>
  )
}
