import cookies from 'js-cookie'
import { useEffect, useState } from 'react'

const cookieName = 'accept_cookies'

export const useAcceptCookies = (): {
  acceptedCookies: boolean
  onAcceptCookies: () => void
} => {
  const [acceptedCookies, setAcceptedCookies] = useState(true)

  useEffect(() => {
    if (!cookies.get(cookieName)) {
      setAcceptedCookies(false)
    }
  }, [])

  const acceptCookies = () => {
    setAcceptedCookies(true)
    cookies.set(cookieName, 'accepted', { expires: 365 })
  }

  return {
    acceptedCookies,
    onAcceptCookies: acceptCookies,
  }
}
