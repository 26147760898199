import { ScrollToTop, ThemeSwitcher } from '@goatlab/react-ui'
import parse, { HTMLReactParserOptions } from 'html-react-parser'
import { FC } from 'react'

interface RichTextDisplayProps {
  content?: string | null
}

export const RichTextDisplay: FC<RichTextDisplayProps> = ({ content }) => {
  const options: HTMLReactParserOptions = {
    replace: domNode => {
      // const tagName = (domNode as any).tagName?.toLowerCase()
      const customComponent = (domNode as any)?.attribs?.[
        'data-custom-react-component'
      ]

      switch (customComponent) {
        case 'ThemeSwitcher': {
          return <ThemeSwitcher />
        }
        case 'ScrollToTop': {
          return <ScrollToTop />
        }
        default: {
          break
        }
      }

      return
    },
  }

  return parse(content || '', options)
}
