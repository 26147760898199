import cn, { clsx } from 'clsx'
import { FC, AnchorHTMLAttributes, MouseEventHandler } from 'react'
import { EditText } from 'react-edit-text'
import { ImageFixedHeight } from './images/ImageFixedHeight'
import Link from '@components/ui/Link'
import { siteSettings } from '@src/api/settings/site-settings'

type LogoProps = {
  text?: string
  href?: string
  logoUrl?: string
  keepText?: boolean
  editable?: boolean
  onTextChanged?: (text: string) => void
  onMouseEnter?: MouseEventHandler<HTMLAnchorElement> | undefined
} & AnchorHTMLAttributes<HTMLAnchorElement>

export const Logo: FC<LogoProps> = ({
  className,
  href,
  editable,
  keepText,
  text,
  onTextChanged,
  logoUrl,
}) => {
  const textClass = clsx('font-semibold text-gray-600 pl-2', {
    hidden: !keepText,
  })

  return (
    <Link
      href={editable ? '#' : href || siteSettings.logo.href}
      className={cn('inline-flex focus:outline-none items-center', className)}>
      <ImageFixedHeight
        src={logoUrl || siteSettings.logo.url}
        alt={siteSettings.logo.alt}
        height={40}
      />

      {editable ? (
        keepText && (
          <EditText
            className={textClass}
            defaultValue={text || 'Gealium'}
            onSave={e => {
              onTextChanged?.(e.value)
            }}
          />
        )
      ) : (
        <div className={textClass}>Gealium</div>
      )}
    </Link>
  )
}
