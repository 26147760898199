import { useLocalization } from '@goatlab/marketplace-i18n'
import { Button, useScrollHeight } from '@goatlab/react-ui'
import clsx from 'clsx'
import { HomeIcon, MessagesSquareIcon } from 'lucide-react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React, { useRef } from 'react'
import { AccountSwitcher } from '@components/account/AccountSwitcher'
import { useAuth } from '@components/auth/store/auth.store'
import SearchIcon from '@components/icons/search-icon'
import { LanguageSwitcher } from '@components/ui/LanguageSwitcher'
import { Logo } from '@components/ui/Logo'
import { GealiumCategoryMenu } from '@components/ui/menus/GealiumCategoryMenu'
import { SpaceMenu } from '@components/ui/menus/SpaceMenu'
import { NoSSR } from '@components/ui/utils/NoSSR'
import { useSite } from '@contexts/site/site.context'
import { useUI } from '@contexts/ui/ui.context'
import { backendHook } from '@src/api/trpc'
import { useIs } from '@src/hooks/useIs'
import { useStateAwareRoutes } from '@src/hooks/useStateAwareRoutes'
import {
  formatMenuFromCategories,
  formatMenuFromWPMenus,
} from '@src/utils/buildHeaders'
import { useTypedTranslation } from '@src/utils/useTypedTranslation'
import { useAddActiveScroll } from '@utils/add-active-scroll'

const AuthMenu = dynamic(() => import('./authMenu'), { ssr: false })
const CartButton = dynamic(() => import('@components/cart/cartButton'), {
  ssr: false,
})

type DivElementRef = React.MutableRefObject<HTMLDivElement>

interface MainTopHeaderProps {
  logo?: string
}

export const MainTopHeader: React.FC<MainTopHeaderProps> = () => {
  const { t } = useTypedTranslation('common')
  const currentLanguage = useLocalization().currentLanguageBackendFormat()
  const { navbarOffset } = useScrollHeight()
  const { isSpace, menuItems, logo, isExternalDomain } = useSite()
  const router = useRouter()
  const routes = useStateAwareRoutes()

  const { data: gealiumCategories } = backendHook.categories.grouped.useQuery(
    {
      lang: currentLanguage,
    },
    {
      refetchOnWindowFocus: false,
    },
  )

  const shouldDisplaySpaceMenuOnly = !!(isSpace && isExternalDomain)
  const gealiumCategoryMenu = formatMenuFromCategories(gealiumCategories)
  const spaceMenu = formatMenuFromWPMenus(menuItems)

  const { openSearch } = useUI()
  const { isMobile, isAccountPage } = useIs()
  const { isAuthenticated } = useAuth()
  const isUserAuthenticated = isAuthenticated()

  const siteHeaderRef = useRef() as DivElementRef
  useAddActiveScroll(siteHeaderRef)

  return (
    <header
      id="siteHeader"
      ref={siteHeaderRef}
      className={clsx('sticky active z-20 w-full h-14')}>
      <div
        className={clsx(
          'fixed z-20 w-full h-14 text-gray-700 transition duration-200 ease-in-out bg-white body-font ps-6',
        )}
        style={{
          top: `${navbarOffset}px`,
        }}>
        <div className="flex items-center  h-full w-full before:absolute before:w-screen before:h-px before:bg-[#F1F1F1] before:bottom-0 pr-4">
          <div className="w-2/12 inline-flex items-center justify-start">
            <Logo
              keepText={!shouldDisplaySpaceMenuOnly}
              logoUrl={(shouldDisplaySpaceMenuOnly && logo) || ''}
            />
          </div>

          <div className="hidden lg:block w-5/12 flex justify-around 2xl:me-12 3xl:me-20 mr-6">
            <div className="transition-all duration-100 ease-in-out lg:flex ms-7 xl:ms-9 pe-2 headerTopMenu">
              {shouldDisplaySpaceMenuOnly ? (
                <SpaceMenu menu={spaceMenu} />
              ) : (
                <GealiumCategoryMenu categoryMenu={gealiumCategoryMenu} />
              )}
            </div>
          </div>

          <div className="w-5/12 md:w-10/12 transition-all flex justify-end">
            <div className="flex gap-x-3 align-center m-2">
              <div className="flex flex-col justify-center items-center">
                <Button
                  onClick={openSearch}
                  variant="ghost"
                  color="transparent">
                  <div className="flex-col align-center">
                    <div className="flex items-center flex-col">
                      <SearchIcon className="w-4 h-5 mb-1" />
                      <span className="text-xs">{t('search-input-label')}</span>
                    </div>
                  </div>
                </Button>
              </div>

              {isUserAuthenticated && (
                <Button
                  onClick={async () => {
                    await router.push(routes.dynamic.ACCOUNT_HOME())
                  }}
                  variant="ghost"
                  color="transparent">
                  <div className="flex-col align-center">
                    <div className="flex items-center flex-col">
                      <HomeIcon className="w-5 h-5 mb-1" />
                      <span className="text-xs">Home</span>
                    </div>
                  </div>
                </Button>
              )}

              {isUserAuthenticated && (
                <Button
                  onClick={async () => {
                    await router.push(routes.dynamic.ACCOUNT_INBOX())
                  }}
                  variant="ghost"
                  color="transparent">
                  <div className="flex-col align-center">
                    <div className="flex items-center flex-col">
                      <MessagesSquareIcon className="w-5 h-5 mb-1" />
                      <span className="text-xs">{t('text-inbox')}</span>
                    </div>
                  </div>
                </Button>
              )}

              <div className="flex flex-col justify-center items-center">
                <CartButton />
              </div>

              <NoSSR>
                <div className="hidden md:flex">
                  {!isUserAuthenticated && <LanguageSwitcher name="language" />}
                </div>
              </NoSSR>

              {isAccountPage && isUserAuthenticated && <AccountSwitcher />}
              {!isMobile && <AuthMenu />}
            </div>
          </div>
        </div>
      </div>

      {/* {isSpace && spaceMenu.length ? (
        <div
          className={clsx(
            'fixed z-1 w-full h-10 text-gray-700 transition duration-200 ease-in-out bg-white body-font ps-6 border-t border-gray-300 pr-32',
          )}
          style={{
            top: `${navbarOffset + 50}px`,
          }}>
          <div className="flex justify-end items-center  h-full w-full before:absolute before:w-screen before:h-px before:bg-[#F1F1F1] before:bottom-0 pr-4">
            <div className="w-5/12 flex justify-end items-center 2xl:me-12 3xl:me-20 ml-4 mr-6">
              <div className="transition-all duration-100 ease-in-out lg:flex ms-7 xl:ms-9 pe-2 headerTopMenu">
                <SpaceMenu menu={spaceMenu} />
              </div>
            </div>

            <div className="w-3/12 inline-flex items-center justify-end">
              <span className="font-bold uppercase">{accountName}</span>
            </div>
          </div>
        </div>
      ) : null} */}
    </header>
  )
}
