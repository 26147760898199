import { rectSortingStrategy } from '@dnd-kit/sortable'
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from '@goatlab/react-ui'
import { Loader, PlusCircle } from 'lucide-react'
import { FC, useState } from 'react'
import * as React from 'react'
import { GridContainer } from '../dragAndDrop/components/GridContainer'
import { Sortable } from '../dragAndDrop/sortable/Sortable'
import { GealiumMenuItem } from './GealiumMenuItem'
import { GealiumLogo } from '@components/icons/GealiumLogo'
import { siteSettings } from '@src/api/settings/site-settings'
import { MenuItem } from '@src/utils/buildHeaders'
import { useTypedTranslation } from '@src/utils/useTypedTranslation'
import { cn } from '@utils/cn'
import 'react-edit-text/dist/index.css'

export const ListItem = React.forwardRef<
  React.ElementRef<'a'>,
  React.ComponentPropsWithoutRef<'a'>
>(({ className, title, children, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            'block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground',
            className,
          )}
          {...props}>
          <div className="text-sm font-medium leading-none">{title}</div>
          <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
            {children}
          </p>
        </a>
      </NavigationMenuLink>
    </li>
  )
})

interface CategoryMenuProps {
  className?: string
  categoryMenu: MenuItem[]
  editMode?: boolean
  onAddParentMenuClicked?: () => Promise<void>
  onMenuItemChanged?: (item: MenuItem) => Promise<void>
  onAddChildItemMenuClicked?: (item: MenuItem) => Promise<void>
  onDeleteMenuItem?: (item: MenuItem) => Promise<void>
  onUpdateLinkMenuItemClicked?: (item: MenuItem) => Promise<void>
  onReOrderItems?: (props: {
    active: MenuItem
    over: MenuItem
    newOrder: MenuItem[]
  }) => Promise<void>
}
const { siteHeader } = siteSettings

export const GealiumCategoryMenu: FC<CategoryMenuProps> = ({
  categoryMenu,
  editMode,
  onAddParentMenuClicked,
  onMenuItemChanged,
  onDeleteMenuItem,
  onAddChildItemMenuClicked,
  onUpdateLinkMenuItemClicked,
  onReOrderItems,
}) => {
  const [editingItem, setEditingItem] = useState<number | null>(null)
  const [addingParent, setAddingParent] = useState<boolean>(false)
  const [addingChild, setAddingChild] = useState<boolean>(false)

  const { t } = useTypedTranslation('common')

  return (
    <NavigationMenu>
      <NavigationMenuList>
        {!editMode && (
          <>
            <NavigationMenuItem>
              <NavigationMenuTrigger>
                {t('about-gealium')}
              </NavigationMenuTrigger>
              <NavigationMenuContent>
                <ul className="grid gap-3 p-6 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
                  <li className="row-span-4">
                    <NavigationMenuLink asChild href="/">
                      <div className="flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-muted/50 to-muted p-6 no-underline outline-none focus:shadow-md">
                        <GealiumLogo />
                        <div className="mb-2 mt-4 text-lg font-medium">
                          {siteSettings.name}
                        </div>
                        <p className="text-sm leading-tight text-muted-foreground">
                          {siteSettings.description}
                        </p>
                      </div>
                    </NavigationMenuLink>
                  </li>

                  {siteHeader.pagesMenu.map(page => {
                    return (
                      <ListItem
                        href={page.path}
                        title={t(`common.${page.label}` as any)}
                        key={page.label}>
                        {t(`common.${page.description}` as any)}
                      </ListItem>
                    )
                  })}
                </ul>
              </NavigationMenuContent>
            </NavigationMenuItem>

            <NavigationMenuItem>
              <NavigationMenuTrigger>Services</NavigationMenuTrigger>
              <NavigationMenuContent>
                <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] ">
                  {categoryMenu.map(category => {
                    return (
                      <ListItem
                        href={category.path}
                        key={category.label}
                        title={category.label}>
                        <span className="overflow-hidden overflow-ellipsis line-clamp-2">
                          {category?.columns
                            ?.flatMap(column =>
                              column.columnItems.map(item => item.label),
                            )
                            .join(', ')}
                        </span>
                      </ListItem>
                    )
                  })}
                </ul>
              </NavigationMenuContent>
            </NavigationMenuItem>
          </>
        )}
        {editMode && (
          <Sortable
            handle={true}
            items={categoryMenu}
            renderItem={({ item, props: { listeners } }) => (
              <GealiumMenuItem
                menu={item}
                editingItem={editingItem}
                addingChild={addingChild}
                setAddingChild={setAddingChild}
                setEditingItem={setEditingItem}
                onMenuItemChanged={onMenuItemChanged}
                onUpdateLinkMenuItemClicked={onUpdateLinkMenuItemClicked}
                onDeleteMenuItem={onDeleteMenuItem}
                onAddChildItemMenuClicked={onAddChildItemMenuClicked}
                dragAndDropListeners={listeners}
                onReOrderItems={onReOrderItems}
              />
            )}
            Container={(props: any) => <GridContainer {...props} columns={5} />}
            strategy={rectSortingStrategy}
            onReOrderItems={onReOrderItems}
          />
        )}

        {editMode && (
          <NavigationMenuItem
            onClick={async () => {
              setAddingParent(true)
              await onAddParentMenuClicked?.()
              setAddingParent(false)
            }}>
            {!addingParent && !addingChild && !editingItem ? (
              categoryMenu.length < 10 && (
                <PlusCircle className="w-6 h-6 mr-2 text-slate-500 cursor-pointer" />
              )
            ) : (
              <Loader className="animate-spin w-6 h-6 mr-2 text-slate-500" />
            )}
          </NavigationMenuItem>
        )}
      </NavigationMenuList>
    </NavigationMenu>
  )
}
