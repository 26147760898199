import Link from 'next/link'
import type { FC } from 'react'
import { Logo } from '@components/ui/Logo'
import {
  TranslationKeys,
  useTypedTranslation,
} from '@src/utils/useTypedTranslation'

interface Props {
  className?: string
  data: {
    widgetTitle?: TranslationKeys['footer']
    lists: {
      id: string
      path?: string
      title: TranslationKeys['common']
      icon?: any
    }[]
    logo?: any
    description?: string
    isCompanyIntroduction?: boolean
  }
  variant?: 'contemporary'
}

const WidgetLink: FC<Props> = ({ className, data }) => {
  const { widgetTitle, lists } = data
  const { description } = data
  const { t } = useTypedTranslation()

  return (
    <div
      className={`${className} ${data?.isCompanyIntroduction && 'col-span-2'}`}>
      {data?.isCompanyIntroduction ? (
        <div className="me-4 flex flex-col space-y-7 lg:space-y-7.5">
          <Logo keepText={true} />
          <p className="text-sm font-normal text-[#1D1E1F] leading-6 max-w-[334px] ">
            {description}
          </p>
          <ul className="text-xs lg:text-sm text-body flex items-center space-x-3 lg:space-x-3.5">
            {lists.map(list => (
              <li
                key={`widget-list--key${list.id}`}
                className="flex items-baseline">
                {list.icon && (
                  <Link href={list.path ?? '#!'}>
                    <span className="me-3 relative top-0.5 lg:top-1 text-sm lg:text-base">
                      {list.icon}
                    </span>
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <>
          <h4 className="text-heading text-sm md:text-base xl:text-lg font-semibold mb-5 2xl:mb-6 3xl:mb-7">
            {widgetTitle && t(`footer.${widgetTitle}`)}
          </h4>
          <ul className="text-xs lg:text-sm text-body flex flex-col space-y-3 lg:space-y-3.5">
            {lists.map(list => (
              <li
                key={`widget-list--key${list.id}`}
                className="flex items-baseline">
                <Link href={list.path ?? '#!'}>
                  {list.icon && (
                    <span className="me-3 relative top-0.5 lg:top-1 text-sm lg:text-base">
                      {list.icon}
                    </span>
                  )}

                  <span className="transition-colors duration-200 hover:text-black">
                    {t(`common.${list.title}`)}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  )
}

export default WidgetLink
