import { Unpacked } from '@goatlab/js-utils'
import Image from 'next/legacy/image'
import { FC } from 'react'
import { RouterOutputs } from '@api/trpc'
import Link from '@components/ui/Link'
import { defaultProfileImg } from '@components/ui/images/images'
import { useSearch } from '@contexts/search/search.context'
import { useUI } from '@contexts/ui/ui.context'
import { useStateAwareRoutes } from '@src/hooks/useStateAwareRoutes'

interface SearchResultProps {
  item: Unpacked<RouterOutputs['search']['staff']>
}

export const StaffSearchResult: FC<SearchResultProps> = ({ item }) => {
  const stateRouter = useStateAwareRoutes()
  const { closeSearch } = useUI()
  const { resetSearch } = useSearch()

  return (
    <Link
      onClick={() => {
        closeSearch()
        resetSearch()
      }}
      href={`${stateRouter.dynamic.SPACE_HOME({
        space: item?.slug || undefined,
      })}`}
      className="group w-full h-auto flex justify-start items-center">
      <div className="relative flex w-12 h-12 rounded-md overflow-hidden bg-gray-200 flex-shrink-0 cursor-pointer me-4">
        <Image
          src={item?.profilePicture || defaultProfileImg}
          width={96}
          height={96}
          loading="eager"
          alt={item.displayName || ''}
          className="bg-gray-200 object-cover"
        />
      </div>
      <div className="flex flex-col w-full overflow-hidden">
        <h3 className="truncate text-sm text-heading mb-2 font-semibold ">
          {item.displayName}
        </h3>
        <div className="text-heading  text-sm">
          {item.title}
          <del className="ps-2 text-gray-400 font-normal">{}</del>
        </div>
      </div>
    </Link>
  )
}
