import { Button } from '@goatlab/react-ui'
import { LogIn } from 'lucide-react'
import { FC } from 'react'
import { UserNav } from './UserNav'
import { useAuth } from '@components/auth/store/auth.store'
import { useUI } from '@contexts/ui/ui.context'
import { useIs } from '@src/hooks/useIs'
import { useTypedTranslation } from '@src/utils/useTypedTranslation'

const AuthMenu: FC = () => {
  const { user, isAuthenticated } = useAuth()
  const { Modal } = useUI()
  const { isMobile } = useIs()
  const { t } = useTypedTranslation('common')

  function handleLogin() {
    Modal.openLogin()
  }

  return user && isAuthenticated() ? (
    <UserNav />
  ) : isMobile ? (
    <button
      type="button"
      data-cy="sign-in-button"
      onClick={handleLogin}
      className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
      <LogIn className="text-slate-500 group-hover:text-primary h-5" />
      <span className="text-xs text-slate-500 dark:text-gray-400 group-hover:text-primary dark:group-hover:text-primary">
        {t('text-sign-in')}
      </span>
    </button>
  ) : (
    <div className="flex flex-col justify-center items-center">
      <Button
        data-cy="sign-in-button"
        className={
          'text-sm xl:text-base text-heading font-semibold focus:outline-none'
        }
        variant={'ghost'}
        onClick={handleLogin}>
        {t('text-sign-in')}
      </Button>
    </div>
  )
}

export default AuthMenu
