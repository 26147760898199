import { FC } from 'react'
import Copyright from './copyright'
import { footerData } from './footerData'
import Widgets from './widgets'
import { useIs } from '@src/hooks/useIs'

const { widgets, payment } = footerData

export const Footer: FC = () => {
  const { isAccountPage, isSpacePage } = useIs()

  return (
    <footer className="border-heading p-12">
      {isAccountPage || isSpacePage ? null : (
        <Widgets widgets={widgets as any} variant="contemporary" />
      )}

      <Copyright payment={payment} variant="contemporary" />
    </footer>
  )
}
